import React from "react";
import "./pricing.css";

const PricingCards = () => {
  return (
      <div className="main-pricing-container">
    <h1>PRICING PLANS</h1>
    <div className="main-price">
     
      <div class="pack-container">
        <div class="header-p">
          <p class="title">Explorer</p>
          <div class="price-container">
          <span></span> <h2>Free</h2>
          </div>
        </div>
        <div>
          <ul class="lists">

          <li class="list">
              <span>
                <svg
                  aria-hidden="true"
                  stroke="currentColor"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.5 12.75l6 6 9-13.5"
                    stroke-linejoin="round"
                    stroke-linecap="round"
                  ></path>
                </svg>
              </span>
              <p><b>100</b> Particpants</p>
            </li>

            <li class="list">
              <span>
                <svg
                  aria-hidden="true"
                  stroke="currentColor"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.5 12.75l6 6 9-13.5"
                    stroke-linejoin="round"
                    stroke-linecap="round"
                  ></path>
                </svg>
              </span>
              <p>Carbon Emission Estimation</p>
            </li>
            <li class="list">
              <span>
                <svg
                  aria-hidden="true"
                  stroke="currentColor"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.5 12.75l6 6 9-13.5"
                    stroke-linejoin="round"
                    stroke-linecap="round"
                  ></path>
                </svg>
              </span>
              <p>
                <b>1</b> Number of Events
              </p>
            </li>
            <li class="list">
              <span>
                <svg
                  aria-hidden="true"
                  stroke="currentColor"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.5 12.75l6 6 9-13.5"
                    stroke-linejoin="round"
                    stroke-linecap="round"
                  ></path>
                </svg>
              </span>
              <p>
                <b>100 Emails</b> Automated Email Notifications
              </p>
            </li>
            <li class="list">
              <span>
                <svg
                  aria-hidden="true"
                  stroke="currentColor"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 6L18 18M6 18L18 6"
                    stroke-linejoin="round"
                    stroke-linecap="round"
                  ></path>
                </svg>
              </span>
              <p> Report Generation & Downloading</p>
            </li>
            <li class="list">
              <span>
                <svg
                  aria-hidden="true"
                  stroke="currentColor"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                 <path
                    d="M6 6L18 18M6 18L18 6"
                    stroke-linejoin="round"
                    stroke-linecap="round"
                  ></path>
                </svg>
              </span>
              <p>
                <b></b> AI-Powered Advisory
              </p>
            </li>
            <li class="list">
              <span>
                <svg
                  aria-hidden="true"
                  stroke="currentColor"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 6L18 18M6 18L18 6"
                    stroke-linejoin="round"
                    stroke-linecap="round"
                  ></path>
                </svg>
              </span>
              <p>API access</p>
            </li>
            <li class="list">
              <span>
                <svg
                  aria-hidden="true"
                  stroke="currentColor"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                 <path
                    d="M6 6L18 18M6 18L18 6"
                    stroke-linejoin="round"
                    stroke-linecap="round"
                  ></path>
                </svg>
              </span>
              <p>
                <b></b> API integration Support
              </p>
            </li>
            <li class="list">
              <span>
                <svg
                  aria-hidden="true"
                  stroke="currentColor"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                <path
                    d="M6 6L18 18M6 18L18 6"
                    stroke-linejoin="round"
                    stroke-linecap="round"
                  ></path>
                </svg>
              </span>
              <p>
                {" "}
                <b></b> Consulting for Carbon Neutral Event
              </p>
            </li>

            <li class="list">
              <span>
                <svg
                  aria-hidden="true"
                  stroke="currentColor"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 6L18 18M6 18L18 6"
                    stroke-linejoin="round"
                    stroke-linecap="round"
                  ></path>
                </svg>
              </span>
              <p>Report Validation Options</p>
            </li>

            <li class="list">
              <span>
                <svg
                  aria-hidden="true"
                  stroke="currentColor"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 6L18 18M6 18L18 6"
                    stroke-linejoin="round"
                    stroke-linecap="round"
                  ></path>
                </svg>
              </span>
              <p>Carbon Offset Options</p>
            </li>

          </ul>
        </div>
        <div class="button-container">
          <button type="button">Buy Plan</button>
        </div>
      </div>

      {/* Starter */}

      <div class="pack-container-s">
        <div class="header-s">
          <p class="title-s">Professional</p>
          <div class="price-container-s">
            <span>₹</span> <h2> 8.5k</h2> <h3 >/Monthly Billed Annually</h3>
          </div>
        </div>
        <div>
          <ul class="lists-s">

          <li class="list-s">
              <span>
                <svg
                  aria-hidden="true"
                  stroke="currentColor"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.5 12.75l6 6 9-13.5"
                    stroke-linejoin="round"
                    stroke-linecap="round"
                  ></path>
                </svg>
              </span>
              <p><b>1000</b> Particpants</p>
            </li>

            <li class="list-s">
              <span>
                <svg
                  aria-hidden="true"
                  stroke="currentColor"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.5 12.75l6 6 9-13.5"
                    stroke-linejoin="round"
                    stroke-linecap="round"
                  ></path>
                </svg>
              </span>
              <p>Carbon Emission Estimation</p>
            </li>
            <li class="list-s">
              <span>
                <svg
                  aria-hidden="true"
                  stroke="currentColor"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.5 12.75l6 6 9-13.5"
                    stroke-linejoin="round"
                    stroke-linecap="round"
                  ></path>
                </svg>
              </span>
              <p>
                <b>6</b> Number of Events
              </p>
            </li>
            <li class="list-s">
              <span>
                <svg
                  aria-hidden="true"
                  stroke="currentColor"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.5 12.75l6 6 9-13.5"
                    stroke-linejoin="round"
                    stroke-linecap="round"
                  ></path>
                </svg>
              </span>
              <p>
                <b>3000 Emails</b> Automated Email Notifications
              </p>
            </li>
            <li class="list-s">
              <span>
                <svg
                  aria-hidden="true"
                  stroke="currentColor"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.5 12.75l6 6 9-13.5"
                    stroke-linejoin="round"
                    stroke-linecap="round"
                  ></path>
                </svg>
              </span>
              <p> Report Generation & Downloading</p>
            </li>
            <li class="list-s">
              <span>
                <svg
                  aria-hidden="true"
                  stroke="currentColor"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.5 12.75l6 6 9-13.5"
                    stroke-linejoin="round"
                    stroke-linecap="round"
                  ></path>
                </svg>
              </span>
              <p>
                <b></b> AI-Powered Advisory
              </p>
            </li>
            <li class="list-s">
              <span>
                <svg
                  aria-hidden="true"
                  stroke="currentColor"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                   <path
                    d="M4.5 12.75l6 6 9-13.5"
                    stroke-linejoin="round"
                    stroke-linecap="round"
                  ></path>
                </svg>
              </span>
              <p>API access</p>
            </li>
            <li class="list-s">
              <span>
                <svg
                  aria-hidden="true"
                  stroke="currentColor"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                 <path
                    d="M6 6L18 18M6 18L18 6"
                    stroke-linejoin="round"
                    stroke-linecap="round"
                  ></path>
                </svg>
              </span>
              <p>
                <b></b> API integration Support
              </p>
            </li>
            <li class="list-s">
              <span>
                <svg
                  aria-hidden="true"
                  stroke="currentColor"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                 <path
                    d="M4.5 12.75l6 6 9-13.5"
                    stroke-linejoin="round"
                    stroke-linecap="round"
                  ></path>
                </svg>
              </span>
              <p>
                {" "}
                <b></b> Consulting for Carbon Neutral Event
              </p>
            </li>

            <li class="list-s">
              <span>
                <svg
                  aria-hidden="true"
                  stroke="currentColor"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                   <path
                    d="M4.5 12.75l6 6 9-13.5"
                    stroke-linejoin="round"
                    stroke-linecap="round"
                  ></path>
                </svg>
              </span>
              <p>Report Validation Options</p>
            </li>

            <li class="list-s">
              <span>
                <svg
                  aria-hidden="true"
                  stroke="currentColor"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.5 12.75l6 6 9-13.5"
                    stroke-linejoin="round"
                    stroke-linecap="round"
                  ></path>
                </svg>
              </span>
              <p>Carbon Offset Options</p>
            </li>

          </ul>
        </div>
        <div class="button-container-s">
          <button type="button-s">Buy Plan</button>
        </div>
      </div>



      {/* Enterprise */}

      <div class="pack-container">
        <div class="header-p">
          <p class="title">Premium</p>
          <div class="price-container">
          <span>₹</span> <h2> 18k</h2> <h3 >/Monthly Billed Annually</h3>
          </div>
        </div>
        <div>
          <ul class="lists">

          <li class="list">
              <span>
                <svg
                  aria-hidden="true"
                  stroke="currentColor"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.5 12.75l6 6 9-13.5"
                    stroke-linejoin="round"
                    stroke-linecap="round"
                  ></path>
                </svg>
              </span>
              <p><b>1000 & Above</b> Particpants</p>
            </li>

            <li class="list">
              <span>
                <svg
                  aria-hidden="true"
                  stroke="currentColor"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.5 12.75l6 6 9-13.5"
                    stroke-linejoin="round"
                    stroke-linecap="round"
                  ></path>
                </svg>
              </span>
              <p>Carbon Emission Estimation</p>
            </li>
            <li class="list">
              <span>
                <svg
                  aria-hidden="true"
                  stroke="currentColor"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.5 12.75l6 6 9-13.5"
                    stroke-linejoin="round"
                    stroke-linecap="round"
                  ></path>
                </svg>
              </span>
              <p>
                <b>24</b> Number of Events
              </p>
            </li>
            <li class="list">
              <span>
                <svg
                  aria-hidden="true"
                  stroke="currentColor"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.5 12.75l6 6 9-13.5"
                    stroke-linejoin="round"
                    stroke-linecap="round"
                  ></path>
                </svg>
              </span>
              <p>
                <b>Unlimited Emails</b> Automated Email Notifications
              </p>
            </li>
            <li class="list">
              <span>
                <svg
                  aria-hidden="true"
                  stroke="currentColor"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.5 12.75l6 6 9-13.5"
                    stroke-linejoin="round"
                    stroke-linecap="round"
                  ></path>
                </svg>
              </span>
              <p> Report Generation & Downloading</p>
            </li>
            <li class="list">
              <span>
                <svg
                  aria-hidden="true"
                  stroke="currentColor"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                 <path
                    d="M4.5 12.75l6 6 9-13.5"
                    stroke-linejoin="round"
                    stroke-linecap="round"
                  ></path>
                </svg>
              </span>
              <p>
                <b></b> AI-Powered Advisory
              </p>
            </li>
            <li class="list">
              <span>
                <svg
                  aria-hidden="true"
                  stroke="currentColor"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.5 12.75l6 6 9-13.5"
                    stroke-linejoin="round"
                    stroke-linecap="round"
                  ></path>
                </svg>
              </span>
              <p>API access</p>
            </li>
            <li class="list">
              <span>
                <svg
                  aria-hidden="true"
                  stroke="currentColor"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                 <path
                    d="M4.5 12.75l6 6 9-13.5"
                    stroke-linejoin="round"
                    stroke-linecap="round"
                  ></path>
                </svg>
              </span>
              <p>
                <b></b> API integration Support
              </p>
            </li>
            <li class="list">
              <span>
                <svg
                  aria-hidden="true"
                  stroke="currentColor"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                <path
                    d="M4.5 12.75l6 6 9-13.5"
                    stroke-linejoin="round"
                    stroke-linecap="round"
                  ></path>
                </svg>
              </span>
              <p>
                {" "}
                <b></b> Consulting for Carbon Neutral Event
              </p>
            </li>

            <li class="list">
              <span>
                <svg
                  aria-hidden="true"
                  stroke="currentColor"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                   <path
                    d="M4.5 12.75l6 6 9-13.5"
                    stroke-linejoin="round"
                    stroke-linecap="round"
                  ></path>
                </svg>
              </span>
              <p>Report Validation Options</p>
            </li>

            <li class="list">
              <span>
                <svg
                  aria-hidden="true"
                  stroke="currentColor"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                   <path
                    d="M4.5 12.75l6 6 9-13.5"
                    stroke-linejoin="round"
                    stroke-linecap="round"
                  ></path>
                </svg>
              </span>
              <p>Carbon Offset Options</p>
            </li>

          </ul>
        </div>
        <div class="button-container">
          <button type="button">Buy Plan</button>
        </div>
      </div>

    </div>
    </div>
  );
};

export default PricingCards;
