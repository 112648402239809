import { useLocation, Route, Routes } from "react-router-dom";
import EcoIndex from "./EcoIndex";
import EventDetailsSidebar from "./eventDetailsSidebar";
import EnergyConsumption from "./EnergyConsumption";
import MealConsumption from "./MealConsumption";
import MaterialConsumption from "./MaterialConsumption";
import { useEvent } from "./EventDetailsContext";
import React, { useEffect } from "react";
import AttendeeDetails from "./attendeeDetails";
import AttendeeConfirmation from "./AttendeeConfirmation";
import "./DuringEventPlanning.css";
import AttendeeUploadPage from "./AttendeeUploadPage";
import ShuttleTransport from "./ShuttleTransport";

const DuringEventPlanning = () => {
  const location = useLocation();
  const { eventDetails, setEventDetails } = useEvent();

  useEffect(() => {
    if (location.state?.event) {
      setEventDetails(location.state.event);
    }
  }, [location.state, setEventDetails]);

  const isMaterialConsumptionEnabled = eventDetails?.boundary?.includes(
    "Material Consumption"
  );
  return (
    <div className="during-planning">
      <EcoIndex />
      <div className="main-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 d-flex align-items-center mb-3">
              <div class="main-content-header">UPDATE EVENT</div>
            </div>
            <div className="col-md-6 d-flex align-items-center justify-content-end mb-3">
              <h1>{eventDetails?.eventName}</h1>
            </div>
          </div>
          <hr />
          <div className="row p-3">
            <div className="col-md-12 p-0 shadow-box">
              <div className="row">
                <div className="col-md-12">
                  <EventDetailsSidebar eventDetails={eventDetails} />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="during-planning-content">
                    <Routes>
                      <Route
                        path="energy-consumption"
                        element={<EnergyConsumption />}
                      />
                      <Route
                        path="meal-consumption"
                        element={<MealConsumption />}
                      />
                      <Route
                        path="shuttle-transport"
                        element={<ShuttleTransport />}
                      />
                      {isMaterialConsumptionEnabled && (
                        <Route
                          path="material-consumption"
                          element={<MaterialConsumption />}
                        />
                      )}
                      <Route
                        path="attendee-details"
                        element={<AttendeeConfirmation />}
                      />
                      <Route
                        path="attendee-details/summary"
                        element={<AttendeeDetails />}
                      />
                      <Route
                        path="attendee-details/uploadsummary"
                        element={<AttendeeUploadPage />}
                      />
                    </Routes>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DuringEventPlanning;
