import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';
import './DoughnutChart.css';
import { getEmissionPostEventActivity, useApi } from './apiService';

const DoughnutChart3 = React.memo(({ eventId, totalEventEmission }) => {
    const chartRef = useRef(null);
    const canvasRef = useRef(null);
    const [mainData, setMainData] = useState([]);
    const [isDataFetched, setIsDataFetched] = useState(false);
    const [hasPositiveData, setHasPositiveData] = useState(false);
    const [totalEmission, setTotalEmission] = useState(0);
    const api = useApi();

    const colorPalette = [
        '#a18cd1',
        '#ff9a9e',
        '#8fd3f4',
        '#a1c4fd',
        '#8de9d5'
    ];

    const hasAnyPositiveValue = (values) => values.some(value => value > 0);

    useEffect(() => {
        const fetchData = async () => {
            try {
                console.log('Pass eventId:', eventId);
                const data = await getEmissionPostEventActivity(eventId);
                console.log('PostEventEmission Data:', data);
                setMainData(data.mainData);
                setIsDataFetched(true);

                const dataValues = data.mainData.map(item => item.cumulativevalue);
                setHasPositiveData(hasAnyPositiveValue(dataValues));

                const total = dataValues.reduce((acc, value) => acc + value, 0);
                setTotalEmission(total);

            } catch (error) {
                console.error('Error fetching data:', error);
                setIsDataFetched(true);
                setHasPositiveData(false);
            }
        };

        fetchData();
    }, [eventId]);

    useEffect(() => {
        if (!hasPositiveData || mainData.length === 0) return;

        const labels = mainData.map(item => item.emissionType.replace("Quantity of ", ""));
        const dataValues = mainData.map(item => item.cumulativevalue);

        const data = {
            labels,
            datasets: [{
                label: 'Carbon Emission',
                data: dataValues,
                backgroundColor: colorPalette,
                borderWidth: 3,
                borderRadius: 8,
                cutout: '85%',
                borderColor: "#e4e8f6",
                hoverBackgroundColor: colorPalette,
                hoverBorderWidth: 3,
                hoverBorderColor: "#e4e8f6",
                hoverOffset: 6,
                spacing: 3,
            }],
        };

        const ctx = canvasRef.current.getContext('2d');
        if (chartRef.current) {
            chartRef.current.destroy();
        }
        chartRef.current = new Chart(ctx, {
            type: 'doughnut',
            data,
            options: {
                plugins: {
                    tooltip: {
                        usePointStyle: true,
                        callbacks: {
                            label: function (context) {
                                // Add "KgCO₂" suffix and format to two decimal places
                                let label = context.label || '';
                                if (context.parsed) {
                                    const formattedValue = context.parsed.toFixed(2); // Format value to two decimal places
                                    label += `: ${formattedValue} KgCO₂`;
                                }
                                return label;
                            }
                        },
                        backgroundColor: 'rgba(0, 0, 0, 0.7)',
                        titleColor: '#ffffff',
                        bodyColor: '#ffffff',
                        borderColor: 'rgba(75, 192, 192, 1)',
                        borderWidth: 0,
                    },
                    legend: {
                        display: true,
                        position: 'bottom',
                        labels: {
                            generateLabels: (chart) => {
                                const datasets = chart.data.datasets;
                                return datasets[0].data.map((data, i) => ({
                                    text: ` ${chart.data.labels[i]} : ${Number(data.toFixed(2)).toLocaleString()} KgCO₂ (${((data/totalEmission)*100).toFixed(2)}%)`,
                                    fillStyle: datasets[0].backgroundColor[i],
                                    index: i
                                }))
                            },
                            usePointStyle: true,
                            pointStyle: 'circle',
                            padding: 17,
                            font: {
                                size: 14,
                            }
                        },
                        align: 'start',
                    },
                    datalabels: {
                        display: false,
                    },
                },
            },
            plugins: [{
                id: 'textCenter',
                beforeDatasetsDraw(chart) {
                    const { ctx, data } = chart;
                    ctx.save();
                    ctx.font = "12px Rubick, sans-serif";
                    ctx.fillStyle = "#2e688dbb";
                    ctx.textAlign = "center";
                    ctx.textBaseline = "middle";
                    ctx.fillText(`TOTAL`, chart.getDatasetMeta(0).data[0].x, chart.getDatasetMeta(0).data[0].y - 20);
                    ctx.font = "16px Rubik, Lado, sans-serif";
                    ctx.fillStyle = "#2e688d";
                    ctx.fillText(`${totalEmission.toLocaleString()} KgCO₂`, chart.getDatasetMeta(0).data[0].x, chart.getDatasetMeta(0).data[0].y);
                    ctx.fillStyle = "#2e688dbb";
                    ctx.font = "14px Rubick, sans-serif";
                    ctx.fillText(`(${((totalEmission / totalEventEmission) * 100).toFixed(2).toLocaleString()}%)`, chart.getDatasetMeta(0).data[0].x, chart.getDatasetMeta(0).data[0].y + 20);
                },
            }]
        });

        return () => {
            if (chartRef.current) {
                chartRef.current.destroy();
            }
        };
    }, [mainData, hasPositiveData, totalEventEmission]);

    return (
        <div className="chart-container">
            <div className='chart-header'>
                <h3>Emissions of Post-Event Activities</h3>
            </div>
            <div className='chart-data'>
                {isDataFetched && hasPositiveData ? (
                    <canvas ref={canvasRef} id="myChart3" />
                ) : (
                    <div style={{ textAlign: 'center' }}>
                        <br /> <br /> <br /> <br /> <br />
                        <h5>Data Awaited</h5>
                    </div>
                )}
            </div>
        </div>
    );
});

export default DoughnutChart3;