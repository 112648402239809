import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import EcoIndex from "./EcoIndex";
import OrganizerDetailsSidebar from "./OragniserDetailsSidebar"; // Corrected Typo
import OrganizerData from "./OrganizerData";
import { useEvent } from "./EventDetailsContext";
import AttendeeDetaileventDetailsSidebar from "./AttendeeDetaileventDetailsSidebar"; // Corrected Typo
import EventDetailsSidebar from "./eventDetailsSidebar"; // Assuming you meant this
import './Organizer.css';

const Organizer = () => {
  const { eventDetails, setEventDetails } = useEvent();
  const location = useLocation();

  useEffect(() => {
    if (location.state?.event) {
      setEventDetails(location.state.event);
    }
  }, [location.state, setEventDetails]);

  if (!eventDetails) {
    return <div>Loading event details...</div>;
  }

  return (
    <div className="organizer">
      <EcoIndex />
      <div className="main-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 d-flex align-items-center mb-3">
              <div class="main-content-header">UPDATE EVENT</div>
            </div>
            <div className="col-md-6 d-flex align-items-center justify-content-end mb-3">
              <h1>{eventDetails?.eventName}</h1>
            </div>
          </div>
          <hr />
          <div className="row p-3">
            <div className="col-md-12 p-0 shadow-box">
              <div className="row">
                <div className="col-md-12">
                  {eventDetails.myRole === "Attendee" ? (
                    <AttendeeDetaileventDetailsSidebar eventDetails={eventDetails} />
                  ) : eventDetails.myRole === "Organizer" ? (
                    <OrganizerDetailsSidebar eventDetails={eventDetails} />
                  ) : eventDetails.myRole ? (
                    <EventDetailsSidebar eventDetails={eventDetails} />
                  ) : (
                    <div>No Sidebar Available</div>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="organizer-content">
                    <Routes>
                      <Route path="Organizer-info" element={<OrganizerData eventDetails={eventDetails} />} />
                    </Routes>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Organizer;
