import React, { useState, useEffect } from "react";
import "./MealConsumption.css";
import "../src/assets/styles/bootstrap-4.4.1.css";
import "../src/assets/styles/style.css";
import {
  GetMealConsumptionList,
  postMealConsumptionList,
  useApi,
} from "./apiService";
import { useEvent } from "./EventDetailsContext";
import EventName from "./EventName";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import CustomAlert from "./CustomAlert"; // Import CustomAlert component
import { Colors } from "chart.js";

const MealConsumption = () => {
  const { eventDetails } = useEvent();
  const api = useApi();
  const navigate = useNavigate();
  const [isAlcohol, setIsAlcohol] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    plateConsumed: { id: 6, value: "" }, 
    totalMealCount: { id: "", value: "" }, 
    snackHighTeaCount: { id: "", value: "" }, 
    softDrinkCount: { id: "", value: "" }, 
    hotDrinkCount: { id: "", value: "" },
    Alcohol: { id: "", value: "" }, 
  });

  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState("success");

  useEffect(() => {
    if (eventDetails) {
      const fetchData = async () => {
        try {
          const data = await GetMealConsumptionList(eventDetails.eventId);
          const mealTypeArray = eventDetails.mealType.split("|");
          setIsAlcohol(mealTypeArray.includes("Alcohol"));

          setFormData({
            plateConsumed: {
              id: data.find(item => item.mealName === 'Veg')?.mealConsumptionId || '',
              value: data.find(item => item.mealName === 'Veg')?.quantity || '',
            },
            totalMealCount: {
              id: data.find(item => item.mealName === 'totalMealCount')?.mealConsumptionId || '',
              value: data.find(item => item.mealName === 'totalMealCount')?.quantity || '',
            },
            snackHighTeaCount: {
              id: data.find(item => item.mealName === 'Snack / High Tea Count')?.mealConsumptionId || '',
              value: data.find(item => item.mealName === 'Snack / High Tea Count')?.quantity || '',
            },
            softDrinkCount: {
              id: data.find(item => item.mealName === 'Soft Drink Count')?.mealConsumptionId || '',
              value: data.find(item => item.mealName === 'Soft Drink Count')?.quantity || '',
            },
            hotDrinkCount: {
              id: data.find(item => item.mealName === 'Hot Drink Count')?.mealConsumptionId || '',
              value: data.find(item => item.mealName === 'Hot Drink Count')?.quantity || '',
            },
            Alcohol: {
              id: data.find(item => item.mealName === 'Alcohol')?.mealConsumptionId || '',
              value: data.find(item => item.mealName === 'Alcohol')?.quantity || '',
            },
          });
        } catch (error) {
          setError(error);
        } finally {
          setLoading(false);
        }
      };
      fetchData();
    }
  }, [eventDetails]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const handleChange = (e, field) => {
    const value = e.target.value; // Convert to number or set to 0
    setFormData({
      ...formData,
      [field]: {
        ...formData[field],
        value: value,
      },
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Prepare postData array
    const postData = [
      {
        MealConsumptionId: formData.plateConsumed.id,
        EventId: eventDetails.eventId,
        Quantity: formData.plateConsumed.value,
      },
      // {
      //   MealConsumptionId: formData.totalMealCount.id || 1,
      //   EventId: eventDetails.eventId,
      //   Quantity: formData.totalMealCount.value || '0',
      // },
      {
        MealConsumptionId: formData.snackHighTeaCount.id || 2,
        EventId: eventDetails.eventId,
        Quantity: formData.snackHighTeaCount.value,
      },
      {
        MealConsumptionId: formData.softDrinkCount.id ||3,
        EventId:eventDetails.eventId,
        Quantity: formData.softDrinkCount.value,
      },
      {
        MealConsumptionId: formData.hotDrinkCount.id || 4,
        EventId: eventDetails.eventId,
        Quantity: formData.hotDrinkCount.value,
      },
      {
        MealConsumptionId: formData.Alcohol.id || 5,
        EventId: eventDetails.eventId,
        Quantity: formData.Alcohol.value || '0',
      },
    ];
console.log("data to be sent: ",postData);
    // Check for empty or invalid data
    const errors = postData.filter(item => !item.Quantity && item.MealConsumptionId);
    if (errors.length > 0) {
      setAlertMessage("Please fill out all required fields.");
      setAlertType("error");
      setShowAlert(true);
      return;
    }

    try {
      await postMealConsumptionList(postData);
      console.log("Data saved : ",postData);
      setAlertMessage("Data saved successfully.");
      setAlertType("success");
      setShowAlert(true);
    } catch (error) {
      setAlertMessage("Error saving data.");
      setAlertType("error");
      setShowAlert(true);
    }
  };
  

  const handleCloseAlert = () => {
    setShowAlert(false);
    if (alertType === "success") {
      if (eventDetails.isShuttleService) {
        navigate("/events/during-event-planning/shuttle-transport");
      } else {
        navigate("/events/post-event-planning/waste-generation");
      }
    }
  };
  

  const isAttendee = eventDetails?.myRole === "Attendee";
  if (isAttendee) {
    return (
      <div className="access-restricted">
        <p style={{ fontSize: "25px", color: "#007272" }}>
          Access Restricted to Event Managers Only
        </p>
        <p style={{ fontSize: "30px", color: "#007272" }}>
          Please fill your details in the{" "}
          <strong>
            <span style={{ color: "#000000" }}>"INDIVIDUAL"</span>
          </strong>{" "}
          Tab
        </p>
      </div>
    );
  } else {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <form
              onSubmit={handleSubmit}
              onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault(); }}
              className="form-group meal-consumption"
            >
              <div className="row mb-3">
                <div className="col-md-4">
                  <label>Number of Plates Consumed</label>
                  <input
                    type="number"
                    className="form-control"
                    value={formData.plateConsumed?.value || ""} 
                    onChange={(e) => handleChange(e, "plateConsumed")}
                    min={0}
                    max={9999999999}
                  />
                </div>
                <div className="col-md-4">
                  {/* <div className="form-group Total_Meal_Count">
                    <label>Total Meal Count</label>
                    <input
                      required
                      type="number"
                      name="totalMealCount"
                      className="form-control"
                      value={formData.totalMealCount.value}
                      onChange={(e) => handleChange(e, "totalMealCount")}
                      min={0}
                      max={9999999999}
                    />
                  </div> */}
                  <div className="form-group">
                    <label>Snack / High Tea </label>
                    <input
                      required
                      type="number"
                      name="snackHighTeaCount"
                      className="form-control"
                      value={formData.snackHighTeaCount.value}
                      onChange={(e) => handleChange(e, "snackHighTeaCount")}
                      min={0}
                      max={9999999999}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <label>Soft Drink </label>
                  <div className="input-group">
                    <input
                      required
                      type="number"
                      name="softDrinkCount"
                      className="form-control"
                      value={formData.softDrinkCount.value}
                      onChange={(e) => handleChange(e, "softDrinkCount")}
                      min={0}
                      max={9999999999}
                    />
                    <div className="input-group-append">
                      <span className="Serves_Txt">Litre</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-4">
                  <label>Alcohol</label>
                  <div className="input-group">
                    <input
                      required
                      type="number"
                      name="Alcohol"
                      className="form-control"
                      value={formData.Alcohol?.value || 0}
                      onChange={(e) => handleChange(e, "Alcohol")}
                      disabled={!isAlcohol}
                      min={0}
                      max={9999999999}
                    />
                    <div className="input-group-append">
                      <span className="Serves_Txt">Litre</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <label>Tea/ Coffee </label>
                  <div className="input-group">
                    <input
                      required
                      type="number"
                      name="hotDrinkCount"
                      className="form-control"
                      value={formData.hotDrinkCount.value}
                      onChange={(e) => handleChange(e, "hotDrinkCount")}
                      min={0}
                      max={9999999999}
                    />
                    <div className="input-group-append">
                      <span className="Serves_Txt">Litre</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 text-right">
                  <button type="submit" className="btn save-button">
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        {showAlert && (
          <CustomAlert
            message={alertMessage}
            type={alertType}
            onClose={handleCloseAlert}
          />
        )}
      </div>
    );
  }
};

export default MealConsumption;
